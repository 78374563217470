  .col {
    border-bottom: 1px solid #AAAAAA;
    padding: 4px 2px;
    font-size: 1em;
    font-weight: 600;
  }

  .info-row {
    padding-top: 1%;
  }

  .info-row:nth-of-type(even) {
    background: #C5DACF;
  }

  .info-row div:nth-of-type(even) {
    font-weight: bold;
  }

  .dealer-info {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -8px;
  }

  .internal-info {
    /* padding: 4px 2px; */
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -3px;
  }

  .internal-info div {
    color: '#2b6448';
  }
