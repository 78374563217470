table.kpi-results {
  margin: 10px;
  width: calc(100% - 20px);
}

table.kpi-results tbody tr:nth-child(odd),
table.kpi-results tbody tr:nth-child(odd) input,
table.kpi-results tbody tr:nth-child(odd) select {
  background-color: #f0f0f0;
}

table.kpi-results th {
  text-align: center;
}

table.kpi-results td {
  border: 1px solid black;
}

table.kpi-results td select {
  height: 34px;
  border: 0px;
}

table.kpi-results td.goal,
table.kpi-results td.actual,
table.kpi-results td.detail {
  text-align: center;
}

div.kpi-progress span {
  display: inline-block;
  width: 4em;
  text-align: right;
}

div.kpi-progress div.kpi-progress-bar {
  width: 300px;
  border: 1px solid black;
  background-color: white;
  margin: 4px;
  display: inline-block;
  text-align: left;
}

div.kpi-progress div.kpi-progress-complete {
  background-color: #2b6448;
  display: inline-block;
}
