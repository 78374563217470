.fee-table-footer {
  display: flex;
  justify-content: center;
}

.fee-table {
  max-width: 100%;
  margin-top: 32px;
}

.adjustable-prepend{
  padding: 2px;
}

.form-control.dollar-input {
  padding: 0px 3px;
}

.fee-add-remove-button {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
}


.fee-button-header {
  width: 10%;
}

.principal-amount-buttons {
  display: flex;
  width: 50%;
}

.input-group-prepend {
  max-height: 38px;
}
