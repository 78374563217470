.dashboard-column {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dashboard-column h2, .market-stats h2 {
  font-size: 1.3rem;
  font-weight: 900;
  color: #2b6448;
  text-align: center;
}

.dashboard-column.dealer-stats-column {
  padding-right: 40px;
}

.dashboard-column.tasks {
  padding-right: 30px;
}

.dashboard-column .dealer-stats {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-column table.statuses {
  width: 45%;
  font-weight: 700;
}

@media only screen and (max-width: 900px) {
  .dashboard-column .dealer-stats {
    flex-direction: column;
  }

  .dashboard-column table.statuses {
    width: 100%;
  }
}

.dashboard-column table.statuses td {
  padding-bottom: 6px;
}

.dashboard-column table.statuses tr :nth-child(2),
.dashboard-column table.statuses tr :nth-child(3) {
  text-align: right;
}

.dashboard-column table.statuses tr a {
  color: inherit;
}

.dashboard-column .warning,
.dashboard-column table.statuses .warning th,
.dashboard-column table.statuses .warning td {
  color: red;
}

.dashboard-column.tasks a,
.dashboard-column.tasks a:link,
.dashboard-column.tasks a:visited,
.market-stats a,
.market-stats a:link,
.market-stats a:visited {
  color: inherit;
}
