  hr {
      border-bottom: 5px solid #d68e00;
  }
  .xlf-green {
      color: #2b6448;
  }
  .container{
      width:100%;
      max-width: 100%;
  }
  .small {
    height: 20px;
  }
  @media (max-width: 992px) {
    .small{
      height: 100%;
    }
  }

  .navbar-light .navbar-nav.xlf-green .nav-link {
    color:#2b6448;
  }

  .navbar-light .navbar-nav.xlf-yellow .nav-link {
    color:#d68e00;
  }

  @media (min-width: 992px) {
    .navbar-light .navbar-nav.xlf-yellow .nav-link::after {
      content: "                ";
      white-space: pre;
    }
    .navbar {
      padding-top:0px;
      padding-bottom:0px;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
  .xlf-yellow-user {
    color:#d68e00;
    font-size:2em;
  }
  .bg-xlf-yellow {
      background-color:#d68e00;
  }
  .bg-xlf-green {
      background-color:#2b6448;
  }

  label {
    display: flex;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    margin-left: 1px;
    padding: 0;
  }

  .page-item.active .page-link {
    background-color: #2b6448;
    border-color: #2b6448;
  }

  .page-link {
    color: #2b6448;
  }

  .page-link:hover {
    color: #2b6448;
    border-left: 1px solid black;
    background-color: #d68e00;
  }
  .navbar-dark .navbar-nav.white .nav-link {
      color: rgba(255,255,255,1);
  }
  .form-inline{
      display: inline-flex;
  }

  table.dealer-info-table {
    background-color: #FFFFFF;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  table.dealer-info-table td, table.dealer-info-table th {
    border-bottom: 1px solid #AAAAAA;
    padding: 4px 2px;
  }

  table.dealer-info-table tr:nth-child(even) {
    background: #C5DACF;
  }

  :root {
  --breakpoint-xxl: 1400px;
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xxl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}
