.datatables-actions {
  flex-direction: row
}

.datatables-actions a {
  padding-right: 6px;
  padding-left: 6px;
  border-left: 1px solid black;
  cursor: pointer;
}

.datatables-actions a:first-child {
  border-left: 0px;
}
