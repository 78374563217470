  a:link {
    color: rgb(43, 100, 72);
  }

  a:visited {
    color: black;
  }

  .col {
    border-bottom: 1px solid #AAAAAA;
    padding: 4px 2px;
    font-size: 1em;
    font-weight: 600;
  }

  .info-row {
    margin-left:0.5px;
    margin-right:0.5px;
  }

  .info-row:nth-of-type(even) {
    background: #C5DACF;
  }

  .info-row.warning {
    background: #e64a31;
    color: white;
    font-size: 1.2rem;
  }

  .info-row.warning:nth-of-type(even) {
    background: #e64a31;
  }

  .info-row.warning a {
    color: white;
    text-decoration: underline;
  }

  .info-row.actions button {
    font-weight: bold;
  }

  .info-row.actions button, .info-row.actions a {
    padding-left: 5px !important;
    margin-right: 5px;
    border-left: 1px solid #212529 !important;
  }

  .info-row.actions button:nth-child(1), .info-row.actions a:nth-child(1) {
    border-left: 0px solid #212529 !important;
  }

  .info-row div:nth-of-type(even) {
    font-weight: bold;
  }

  .info-row .col:nth-of-type(2) {
    max-width: 200px;
  }

  .info-section {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -8px;
  }

  .internal-info {
    font-size: 1.2em;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -3px;
  }

  .internal-info div {
    color: '#2b6448';
  }

  .lot-image {
    max-height: 220px;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container:hover .overlay {
    background: rgba(0, 0, 0, .3);
  }

  .image-container:hover .add-image-text {
    opacity: 1;
  }

  .overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }

  .add-image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    transition: opacity 1s ease;
    font-weight: bold;
  }

  .add-first-image-container {
    transition: background 1s ease;
  }

  .add-first-image-container:hover {
    opacity: .7;
    background-color: #2b6448;
    color: white;
  }

.add-first-image-text {
   padding: 96px;
}


/* Header Table CSS */
.car-info-header{
  border: #2b6448 2px solid;
  color: #2b6448;
  font-weight: normal;
  text-align: center;
  border-bottom: none;
}

.car-info-green {
  background-color: #C5DACF;
  color: #000;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border: #2b6448 2px solid;
  border-top: none;
  padding-bottom: 3px;
}

.car-info-sold {
  background-color: red;
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border: #2b6448 2px solid;
  border-top: none;
  padding-bottom: 3px;
}

td.car-info-green a:link {
  color: #000;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

td.car-info-green a:visited {
  color: #000;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

.dealer-status {
  background-color: #2b6448;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: #2b6448 2px solid;
}

.dealer-status-active-locks {
  background-color: red;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: red 2px solid;
}

.car-status-Paid {
  background-color: #2b6448;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: #2b6448 2px solid;
}

.car-status-sold {
  background-color: red;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: #2b6448 2px solid;
}

.car-status-Writeoff {
  background-color: red;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: red 2px solid;
}

.car-status-Voided {
  background-color: #d68e00;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: #d68e00 2px solid;
}

.car-status-Unpaid {
  background-color: #d68e00;
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border: #d68e00 2px solid;
}

.modal-90w {
  width: 90%;
  max-width: 90%;
}

.info-link-box {
  border: #2b6448 1px solid;
  color: #2b6448;
  font-weight: normal;
  text-align: center;
}

.info-link-box a {
  display: block;
  text-decoration: underline;
  cursor: pointer;
}

.info-link-box a:visited {
  color: rgb(43, 100, 72);
}
