.form-control.is-invalid {
  padding: 6px 12px !important;
}

.term-line-errors.form-group {
  margin-bottom: 0;
}


.term-lines-container {
  display: flex;
  flex-wrap: wrap;
}

.term-line-errors {
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  font-size: 80%;
  color: #dc3545;
}

.term-line-errors ul {
  padding-left: 0;
  margin-bottom: 0;
}

.term-line-errors li {
  margin-left: 30px;
}

.errors-container {
  display: flex;
 }

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

